import Layout from "../../components/Layout";
import "./index.css";

const index = () => {
  return (
    <Layout>
      <h1>Glossary</h1>
      <h3>
        Fun Flamenco Glossary, How/What To Wear on each palo( style) with fun
        images is Coming soon!
      </h3>
    </Layout>
  );
};

export default index;
