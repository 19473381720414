import Layout from "../../components/Layout";
import "./index.css";

const index = () => {
  return (
    <Layout>
      <h1>Coming Soon!</h1>

      <h3>
        In the meantime visit: &nbsp;
        <a
          className='footer-link'
          href='https://www.aleflamenco.com/'
          target='_blank'
          rel='noopener'
        >
          AleFlamenco.com
        </a>
      </h3>
      <div style={{ height: "9rem" }}></div>
    </Layout>
  );
};

export default index;
