// import { Link } from "react-router-dom";
import "./index.css";

const FooterHome = () => {
  let thisyear = new Date().getFullYear();
  // document.getElementById("year").innerHTML = thisyear; =>DOM

  return (
    <div className='home-footer'>
      <p className='footer'>
        &copy;<span id='year'>{thisyear}</span>
        &nbsp;Garrotin | All Rights Reserved
        <br />
        <span className='web-footer'>
          Web Design: &nbsp;
          {/* <Link
            className='footer-link'
            to='https://onemissionstudio.com/'
            target='_blank'
            rel='noopener'
          >
            OneMissionStudio.com
          </Link> */}
          <a
            className='footer-link'
            href='https://onemissionstudio.com/'
            target='_blank'
            rel='noopener'
          >
            OneMissionStudio.com
          </a>
        </span>
      </p>
    </div>
  );
};

export default FooterHome;
