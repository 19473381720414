import { useState } from "react";

import "./index.css";
import { Link } from "react-router-dom";
import Canes from "../../img/candy-canes.png";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const currPath = window.location.pathname;
  console.log(currPath);
  const menuItems = [
    { page: "Home", path: "/" },
    { page: "About", path: "/about" },
    { page: "Glossary", path: "/glossary" },
    { page: "Shop", path: "/shop" },
  ];
  return (
    <div className=''>
      <div className='navIcon '>
        {showMenu ? (
          <div className='navContainer' onClick={() => setShowMenu(!showMenu)}>
            <img src={Canes} className='cross' alt='close' />
            <ul>
              {menuItems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={`${item.path}`}
                      // className={`nav-link ${
                      //   item.path === currPath && "active"
                      // }`}
                      className={item.path === currPath ? "active" : "nav-link"}
                    >
                      {item.page}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div
            className='menu-container'
            onClick={() => setShowMenu(!showMenu)}
          >
            <svg
              className='hatlogo'
              width='63'
              height='63'
              viewBox='0 0 63 63'
              onClick={() => showMenu}
            >
              <path
                d='M38.08,54.74c-6.62-0.75-13.66-3.52-19.84-7.81C8.83,40.41,2.45,31.06,1.59,22.54c-0.43-4.23,0.53-8.07,2.77-11.1
c3.77-5.11,10.81-7.49,19.31-6.52c6.62,0.75,13.66,3.52,19.84,7.81c7.73,5.36,13.46,12.61,15.71,19.88
c1.85,5.97,1.2,11.51-1.83,15.61C53.62,53.33,46.58,55.71,38.08,54.74z'
              />
              <path
                className='sthat'
                d='M27.08,5.76L26.7,7.48c5.31,1.13,10.7,3.51,15.59,6.9c6.73,4.67,11.8,10.71,14.28,17.02
c2.39,6.06,2.07,11.61-0.9,15.62c-3.81,5.16-11.52,7.08-20.61,5.15c-5.31-1.13-10.7-3.51-15.59-6.9
c-6.73-4.67-11.8-10.71-14.28-17.02C2.8,22.2,3.12,16.65,6.08,12.63C9.89,7.48,17.6,5.55,26.7,7.48L27.08,5.76 M27.08,5.76
C17.63,3.76,8.99,5.66,4.6,11.61c-6.88,9.32-0.7,25.03,13.81,35.1c5.26,3.65,10.89,6.05,16.26,7.19c9.45,2.01,18.09,0.1,22.48-5.84
c6.88-9.32,0.7-25.03-13.81-35.1C38.08,9.31,32.45,6.9,27.08,5.76L27.08,5.76z'
              />
              <path
                d='M52.43,27.31c-0.44,0.88-0.84,1.7-1.22,2.47c-3.62,7.42-4.81,10.23-8.77,11.53c-3.07,1.01-6.42,0.59-9.44-0.42
c0,0-8.01-2.63-13.19-9.57c-1.33-1.79-2.48-3.86-3.26-6.24c-0.94-2.9-0.55-6.04,1.04-8.65l4.04-6.67c1.89-3.11,5.37-5.04,9.1-4.91
c3.17,0.11,7.36,1.3,12.65,4.83C43.4,9.7,56.85,18.44,52.43,27.31z'
              />
              <path
                className='sthat1'
                d='M23.57,9.28c-1.91,8.44,15.94,23.39,28.03,16.96l0,0c0.46-0.24,0.86,0.39,0.44,0.68
c-1.4,0.96-3.03,1.62-4.72,1.96c-12.24,2.45-27.41-11.38-24.5-19.82c0.15-0.45,0.85-0.26,0.75,0.2L23.57,9.28z'
              />
              <path
                className='sthat'
                d='M49.9,32.48c-2.67,5.43-4.02,7.7-7.45,8.83c-3.07,1.01-6.42,0.59-9.44-0.42c0,0-12.66-4.16-16.45-15.81
c-0.94-2.9-0.55-6.04,1.04-8.65l1.49-2.46c-0.67,2.06-0.69,4.29-0.01,6.39c3.78,11.65,16.45,15.81,16.45,15.81
c3.02,1.01,6.37,1.43,9.45,0.42C47.17,35.87,48.51,34.68,49.9,32.48z'
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
