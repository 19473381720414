import Home from "./pages/Home";
import About from "./pages/About";
import Glossary from "./pages/Glossary";
import Shop from "./pages/Shop";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Home />} />

          <Route path='/about' exact element={<About />} />

          <Route path='/glossary' exact element={<Glossary />} />

          <Route path='/shop' exact element={<Shop />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
