import Navbar from "../Navbar";
import Footer from "../Footer";

const Layout = ({ children }) => {
  return (
    <div className='wrapper page-height'>
      <Navbar />
      <div className='content-container'>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
