import Layout from "../../components/Layout";
import "./index.css";
import garrotin1 from "../../img/garrotin1.png";

const About = () => {
  return (
    <Layout className='page-height'>
      <div className='about-container'>
        <div class='circle-top'></div>
        <h1 className='title-about-top'>Garrotin</h1>
        <img className='garrotin1' src={garrotin1} alt='garrotin dancer' />
        <h1 className='title-about-btm'>Garrotin</h1>
        <p className='p-about'>
          What is Garrotin? Garrotin is a style of flamenco(palo) which
          developed in Asturias, in northern Spain. It's one of non Andalusian
          folklore origins. It's belong to the tangos group, with 4 counts
          similar to the Farruca.
        </p>
        <br />
        <p>
          The simple and easy answer from my perspective as a designer, Garrotin
          is a type of flamenco dancing/singing from Spain. Garrotin is
          beautiful and fun. The dancers usually wear a hat. Although there is
          no rule on what to wear or what color of the outfit for dancing
          Garrotin, often you will see them dancing in black high waisted pants,
          white long sleeve shirt with red or white accessories. The high
          waisted pants often be replaced by black ruffles skirt for reasons.
        </p>
      </div>
    </Layout>
  );
};
export default About;
