import Navbar from "./Navbar";
import FooterHome from "./FooterHome";

const Layout = ({ children }) => {
  return (
    <div className='wrapper home-height'>
      <Navbar />
      <div className='content-container'>{children}</div>
      <FooterHome />
    </div>
  );
};

export default Layout;
