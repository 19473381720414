import React, { useEffect } from "react";
import LayoutHome from "../../components/LayoutHome";
import "./index.css";
import anime from "animejs";

const Home = () => {
  useEffect(() => {
    anime({
      targets: ".st0, .st1",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      delay: function (el, i) {
        return i * 1000;
      },
    });
  }, []);

  return (
    <LayoutHome>
      <div className='wrapper-content'>
        <svg
          className='dancer'
          width='600'
          height='700'
          viewBox='0 0 600 700'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g id='Layer_1'>
            <path
              className='st0 head'
              d='M291.53,231.89c0.25,1.04,6.42,15,14.36,15.77c1.91,0.18,3.46-0.62,6.48-2.25
		c8.87-4.78,14.26-12.03,17.18-16.89c2.93-4.9,5.83-7.72,4.5-9.85c-3.96-6.4-37.45-25.67-43.92-25.34
		C284.27,193.61,291.37,229.93,291.53,231.89z'
            />
            <polygon
              className='st0 hat'
              points='272.95,179.52 349.82,228.79 352.35,224 336.86,211.9 347.84,195.29 310.51,168.82 295.19,188.81 
		275.48,176.98 	'
            />
            <path
              className='st0'
              d='M294.63,252.72c9.75,0.47,23.33,2.77,32.1,12.67c7.98,9.01,5.3,16.76,10.98,43.92
		c5.27,25.19,13.51,48.99,13.51,48.99c5.06,14.61,9.65,24.87,6.76,38.01c-0.67,3.04-5.06,4.5-10.42,10.7
		c-7.48,8.66-11.3,12.17-13.23,16.33c-7.86,16.87,61.88,80.29,64.2,86.16c0.15,0.38,4.45,4.7,4.28,9.57c0,0-5.37,2.4-5.12,5.63
		c0.81,10.69,5.7,17.61,5.07,25.34c-0.79,9.71-4.48,17.28-9.54,16.08c-5.26-1.25-12.74-12.38-13.26-16.08
		c-1.28-9.02,0.49-14.12-1.69-15.2c-3.23-1.61-8.18,12.45-14.36,10.14c-4.26-1.59-21.26-39.88-50.68-81.93
		c-28.47-40.71-36.88-77.98-52.37-69.26c-19.92,11.2-56.38,25.89-61.38,39.7c-4.86,13.41,9.43,46.66,25.9,77.71
		c14.64,27.59,4.63,14.22,1.52,20.31c-1.69,3.31-1.23,12.79-4.9,12.35c-5.27-0.63-11.11-1.01-13.51-0.56
		c-3.58,0.67-3.06,8.34-14.51,8.78c-8.6,0.33-18.61,0.94-22.66-0.33c-3.09-0.97-2.14-7.27,0-10.14c4.18-5.59,14.21,0.43,20.27-5.07
		c5.55-5.03,10.67-1.24,5.55-5.03c-3.86-2.85-4.78,1.76-9.08-0.31c-8.73-4.21-7.95-40.56-16.74-64.77
		c-3.83-10.56-7.15-11.2-10.14-22.81c-1.8-6.99-7.51-19.08-1.69-27.03c10.77-14.69,29.12-21.67,48.99-41.39
		c11.64-11.55,9.02-24.71,2.53-34.63c-5.37-8.22-14.32-13.95-11.26-20.55c3.64-7.86-28.18-50.22-16.61-62.22
		c15.39-15.97,62.99-31.17,73.49-32.38c6.47-0.75,8.21,9.84,13.23,9.29c6.74-0.73,10.98-12.88,13.51-23.09'
            />
            <path
              className='st0'
              d='M209.04,273.75c1.01,0.89,22.41,7.47,24.78,14.64c3.91,11.83-4.97,42.34-0.56,48.43
		C239.45,345.38,200.14,265.85,209.04,273.75z'
            />

            <path
              className='st1'
              d='M284.81,201.65c-1.67,5.12-3.25,11.06-0.35,15.6c0.53-0.03,1.06-0.05,1.59-0.08c0.62-1.51,0.86-3.19,0.67-4.82
		c0.82,1.79,1.91,3.46,3.22,4.93c0.3-0.88,0.18-1.89-0.33-2.67c0.48,1.86,2.36,3.26,4.28,3.19c-1.28-3.37-2.56-6.74-3.84-10.1'
            />
          </g>
          <g id='Layer_2'>
            <text
              transform='matrix(1 0 0 1 324.2871 405.3301)'
              className='st3 st4 st5'
            >
              Garrotin
            </text>
          </g>
        </svg>
        <div className='frame'></div>
      </div>
    </LayoutHome>
  );
};
export default Home;
